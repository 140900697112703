var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "设置提货单排序方式",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.handleClose,
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          class:
            _vm.origion && _vm.origion == _vm.PRINT_LOCATION_ENUM[1].value
              ? "contentWrap"
              : "contentWrap1",
        },
        _vm._l(_vm.dataList, function (aItem, aIndex) {
          return _c(
            "div",
            { key: aIndex },
            [
              _c("div", { staticClass: "nameWrap" }, [
                _vm._v(_vm._s(aItem.name) + ":"),
              ]),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: aItem.print_type,
                    callback: function ($$v) {
                      _vm.$set(aItem, "print_type", $$v)
                    },
                    expression: "aItem.print_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("自动排序：按照件数从多到少"),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(" 手动排序,自己设置坑位号 "),
                    _vm.showSetButton
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "red !important" },
                            on: { click: _vm.goToSet },
                          },
                          [_vm._v("去设置")]
                        )
                      : _vm._e(),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _vm._v("自动排序：按照下单重量由高到低"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.sureConfirm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }